#services {
  .text {
    position:relative;
    h2 {
      .icon-set {
        position:absolute;
        width:70px;
        height:70px;
        left:20px;
        top:20px;
      }
      &:before {
        content:"";
      }
    }
  }

}