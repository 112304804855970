#partners {
  padding: 90px 0;
  h3 {
    text-align: center;
    font-size: $font-size-46;
    color: $font-color;
    margin: 0 0 30px 0;
  }
  .swiper-wrapper {
    align-items: center;
  }

  .partner-logo {
    display: block;
    padding: 25px;
    transition: all 0.3s linear;
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 130px;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
}