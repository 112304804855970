#error {
  width:100%;
  height:calc(100vh - 398px);
  min-height:667px;
  position:relative;
  color:$font-color;
  h1 {
    font-size:$font-size-60;
    margin:0 0 $padding-md 0;
    padding:0;
  }
  p, li {
    font-size:$font-size-26;
  }
  ul {
    margin:$padding-sm 0 0 0;
    padding:0;
    list-style:none;
    li {
      padding:4px 0;
      a {
        &:after {
          content:"";
          position:absolute;
          width:100%;
          height:3px;
          left:0;
          right:0;
          margin:0 auto;
          bottom:-1px;
          background:$menu-color;
          transition: ease all 0.15s;
        }
      }
    }
  }
  .content {
    position:relative;
    z-index:1;
  }
}
#bg-image {
  position:absolute;
  width:70%;
  height:100%;
  right:0;
  bottom:0;
  background:url(/images/404.jpg) no-repeat;
  background-position: bottom right;
  background-size:contain;
  z-index:0;
}