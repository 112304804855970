#maps {
  width:100%;
}

.maps-container {
  width:66.66667%;
  float:left;
  height:450px;
  filter: gray;
  -webkit-filter: grayscale(99%);
  -webkit-backface-visibility: hidden;
}
.contact-info {
  float:left;
  width:33.3333%;
  height:450px;
  background:$font-color;
  color:white;
  padding:$padding-sm;
  line-height:30px;
  h3 {
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size:$font-size-30;
    margin:0 0 10px 0;
    padding:0;
  }
  a {
    position:relative;
    color:white;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size:$font-size-18;
    padding-bottom:1px;
    transition: ease all 0.15s;
    &:after {
      content:"";
      position:absolute;
      width:0;
      height:2px;
      left:0;
      right:0;
      margin:0 auto;
      bottom:-1px;
      background:$menu-color;
      transition: ease all 0.15s;
    }
    &:hover,
    &:focus {
      text-decoration:none;
      color:$menu-color;
      &:after {
        width:100%;
      }
    }
  }
  p {
    margin-bottom:$padding-xs;
  }
}