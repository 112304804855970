#persons {
  .contactperson {
    width:100%;
    float:left;
  }
  .image {
    float:left;
    max-width:110px;
  }
  .name {
    float:left;
    margin-left:$padding-sm;
    h3 {
      margin:12px 0 0 0;
      padding:0;
      font-family: UniNeueHeavy, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: $font-size-30;
    }
    .social {
      margin:10px 0 0 0;
      padding:0;
      list-style:none;
      li {
        float:left;
        margin-right:5px;
        a {
          width:40px;
          height:40px;
          background:$font-color;
          display: block;
          position:relative;
          text-align:center;
          padding-top:10px;
          i {
            font-size:$font-size-18;
            color:white;
            &.icon-email {
              font-size:$font-size-14;
            }
          }
        }
      }
    }
  }
}