button.menu-toggle {
  &:hover,
  &:focus,
  &:active {
    box-shadow:none;
    outline:0;
  }
}
.main-nav {
  list-style: none;
  margin: 0;
  padding: 65px 0 0 0;
  position: absolute;
  top: 0;
  left:0;
  width: 260px;
  height: 100%;
  /*overflow: hidden;*/
  li {
    display: inline-block;
    line-height: 20px;
    position: relative;
    width: 100%;
    a {
      color: $font-color;
      font-family: UniNeueRegular, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: $font-size-18;
      display: block;
      padding: 10px 15px 10px 30px;
      text-decoration: none;
      &:hover,
      &:focus,
      &.active {
        background-color: transparent;
        color: #fff;
        text-decoration: none;
      }
    }
    &:first-child {
      padding-left: 30px;
      margin-bottom: 30px;
    }
    &.product {
      a {
        font-family: UniNeueBold, 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
      }
    }
    &.language {
      a {
        font-family: UniNeueBook-Italic, 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
      }
    }
    &.sidebar-contact {
      position: absolute;
      left: 0;
      .sidebar-icons {
        position:absolute;
        right:-40px;
        top:0;
        padding:0;
        display:block;
        cursor:pointer;
        &:focus,
        &.active {
          color:$font-color;
        }
        &:hover {
          color:white;
        }
      }
    }
    &.mail {
      bottom: 118px;
      .sidebar-icons {
        top:9px;
      }
    }
    &.phone {
      bottom: 55px;
      .sidebar-icons {
        top:5px;
      }
      i {
        font-size:25px;
      }
    }
    &:nth-child(5) {
      margin-bottom: 30px;
    }
  }
}
ul.contact {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 65px;
  left: 14px;
  li {
    float: left;
    width: 25px;
    position: relative;
    a {
      text-align: center;
      display: block;
      position: relative;
      width: 25px;
      height: 30px;
      margin-bottom: 30px;
      cursor: pointer;
      i {
        color: $font-color;
        font-size: 19px;
        width: 25px;
        height: 30px;
        left: 0;
        top: 0;
        position: absolute;
        -webkit-transition: font-size 0.25s, margin-left 0.25s;
      }
      &:hover,
      &:focus {
        i {
          font-size: 22px;
          margin-left: -2px;
        }
      }
    }
    &:last-child {
      a {
        margin-bottom: 0;
        i {
          font-size: 25px;
        }
        &:hover,
        &:focus {
          i {
            font-size: 28px;
            margin-left: -1px;
            margin-top: -2px;
          }
        }
      }
    }
  }
}



