#search {
  position:relative;
}
#search-term {
  margin-top:80px;
  .term {
    font-size:$font-size-60;
    text-transform:uppercase;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight:normal;
    font-style:normal;
    line-height:100%;
    position:relative;
    span {
      position:relative;
    }
    a {
      margin-left:30px;
      right:-55px;
      top:0;
      position:absolute;
      font-size:40px;
      color:$menu-color;
      &:hover,
      &:focus {
        color:white;
        &:after {
          width:0;
          height:0;
        }
      }
    }
  }
}
#search-form {
  .form-control {
    height:60px;
    border:0;
    font-size:$font-size-60;
  }
}
#results {
  .total {
    color:$font-color;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight:normal;
    font-style:normal;
    font-size:$font-size-18;
    width:100%;
    float:left;
  }
  ul {
    margin:30px 0 0 0;
    padding:0;
    list-style:none;
    float:left;
    width:100%;
    li {
      float:left;
      width:100%;
      position:relative;
      margin:0 0 30px 0;
      a {
        h2 {
          margin:0 0 10px 0;
          padding:0;
          color:$font-color;
          font-family: UniNeueHeavy, 'Roboto', sans-serif;
          font-weight:normal;
          font-style:normal;
          font-size:$font-size-30;
          .label-primary {
            background:$font-color;
            font-weight:300;
            margin-right:8px;
            line-height:30px;
            padding:8px 12px;
          }
        }
        p {
          font-family: UniNeueRegular, 'Roboto', sans-serif;
          font-weight:normal;
          font-style:normal;
          font-size:$font-size-18;
          line-height:1.8em;
          color:$font-color;
        }
        &:hover,
        &:focus {
          h2, p {
            color:$font-color;
          }
          &:after {
            width:0;
            height:0;
          }
        }
      }
    }
  }
  ul.pagination {
    float:none;
    width:auto;
    li {
      width:auto;
      &.active {
        span {
          background:$menu-color;
          color:white;
        }
      }
      a {
        color:$font-color;
        &:hover,
        &:focus {
          background:$font-color;
          color:white;
          border-color:$font-color;
        }
      }
      span, a {
        padding:8px 12px 6px 12px;
      }
    }
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    border-color:$menu-color;
  }
}