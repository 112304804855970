header {
  position:relative;
  width:100%;
  overflow:hidden;
  &.full {
    height:100vh;
    min-height:100vh;
    position:relative;
    overflow:hidden;
    background:$font-color;
    background-size:cover;

    .full-video {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      min-width: 100%;
      min-height: 100%;
      position: relative;
      top: 0;
      left: 0;
      right:0;
      margin:0 auto;
      overflow: hidden;
      z-index: 0;
    }

    .video-content {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right:0;
      margin:0 auto;
      overflow: hidden;
      iframe {
        left:-100%;
        top:0;
        position:absolute;
      }
    }
    .header-title {
      position:absolute;
      bottom:56px;
      left:64px;
      width:80%;
    }
    h1 {
      position:relative;
      display:block;
      font-family: UniNeueHeavy;
      font-weight:normal;
      font-style:normal;
      font-size:$font-size-60;
      color:white;
      margin:0;
      padding:0;
      line-height:70px;
      z-index:12;
      width:50%;
    }
    h2 {
      position:relative;
      display:block;
      font-family: UniNeueHeavy;
      font-weight:normal;
      font-style:normal;
      font-size:$font-size-30;
      color:white;
      margin:25px 0 0 0;
      padding:0;
      line-height:40px;
      z-index:12;
      width:80%;
    }
    &:after {
      content:"";
      width:100%;
      height:50%;
      position:absolute;
      left:0;
      right:0;
      bottom:0;
      z-index:10;
      background: rgba(248,80,50,1);
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.85)));
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
      background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
      background: -ms-linear-gradient(top, rgba(0,0,50,0) 0%, rgba(0,0,0,0.85) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );

    }
  }
  #slide {
    margin-top:80px;
    min-height:450px;
  }
}

.html5-main-video {
  left:-50% !important;
}

/***---------- Preloader -----------***/

.preloader {
  position: fixed;
  z-index: 999999;
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  background: url(../images/preloader.gif) no-repeat center #fff;
}

/*

.video-background {
  background: url('/images/headers/bg-video.jpg') no-repeat;
  background-size:cover;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 0;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#vidtop-content {
  top: 0;
  color: #fff;
}
.vid-info { position: absolute; top: 0; right: 0; width: 33%; background: rgba(0,0,0,0.3); color: #fff; padding: 1rem; font-family: Avenir, Helvetica, sans-serif; }
.vid-info h1 { font-size: 2rem; font-weight: 700; margin-top: 0; line-height: 1.2; }
.vid-info a { display: block; color: #fff; text-decoration: none; background: rgba(0,0,0,0.5); -webkit-transition: .6s background; transition: .6s background; border-bottom: none; margin: 1rem auto; text-align: center; }
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
  .vid-info { width: 50%; padding: .5rem; }
  .vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
  .vid-info .acronym { display: none; }
}
*/
