@media only screen and (min-width: 768px) {
  a {
    transition: ease all 0.15s;
    &:after {
      transition: ease all 0.15s;
    }
  }
  .sidebar {
    .links {
      ul {
        li {
          a, button {
            &:before {
              transition: ease all 0.3s;
            }
            &:after {
              transition: ease all 0.15s;
            }
          }
        }
      }
    }
  }
  .text-container {
    a {
      &:after {
        transition: ease all 0.15s;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .menu-container {
    width:54px;
    height:54px;
    left:0;
    background:transparent;
    transition:all 0.25s ease;
    overflow:hidden;
  }
  .menu-container nav {
    display:none;
  }
  .menu-container.active {
    width:100%;
    height:100%;
    left:0;
    background:$menu-color;
    overflow:hidden;
  }
  .menu-container.active {
    .btn-scrollup {
      display:none !important;
    }
  }
  .menu-container.active nav {
    display:block;
  }
  .text-wrapper {
    position:relative;
    left:0;
    padding-left:0;
  }
  .text-wrapper.active {
    left:0;
  }
  .top-bar {
    left:0;
    padding-left:60px;
  }
  .top-bar.active {
    left:0;
  }



  .fixed-menu {
    background: transparent;
    ul.contact {
      display: none;
    }
  }
  .btn-scroll {
    display: none;
  }

  header {
    &.full {
      .header-title {
        left:15px;
        width:100%;
        h1 {
          line-height: 54px;
          font-size: $font-size-46;
          max-width: 100%;
        }
        h2 {
          margin:15px 0 0 0;
          width:90%;
          font-size:$font-size-24;
        }
      }

    }
    .btn-scroll {
      right: 15px;
      bottom: 45px;
    }
  }

  #slide {
    height: 250px !important;
    min-height: 250px !important;
  }

  .container {
    padding-left: 15px;
    padding-right:15px;
  }

  section {
    &.padded {
      padding: $padding-sm 0;
    }
  }

  .text-intro {
    h1, h2 {
      font-size: $font-size-30;
    }
  }

  .sidebar .blue-block {
    max-width: 100%;
  }

  .row-blocks {
    .col-md-4 {
      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }
  }
  .btn-arrow, .btn-send {
    font-size: $font-size-16;
  }
  .btn-news {
    margin-bottom: 15px;
  }
  .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    z-index: 999;
  }
  .btn-block-blue {
    min-height: 1px;
    margin-bottom: 15px;
    h3 {
      font-size: $font-size-30;
    }
  }
  .btn-scrollup.show {
    opacity: 0.5 !important;
  }
  .expertise {
    max-width: 100%;
    .text {
      min-height: 150px;
      padding: 15px 15px 50px 15px;
    }
  }
  #about {
    .text-intro {
      margin-bottom: $padding-xs;
    }
  }
  .about-item {
    .image-item,
    .text-item {
      width: 100% !important;
    }
    .image-item {
      height: 250px !important;
    }
    .text-item {
      h2 {
        font-size: $font-size-36;
      }
      p {
        height: auto;
        margin: 0 0 80px 0;
        font-size: $font-size-20;
      }
    }
  }
  .options {
    .option {
      ul {
        margin: 0;
      }
    }
    .col8,
    .col4 {
      width: 100%;
    }
    .col8 {
      padding-right: 0;
    }
  }
  .carousel-container {
    margin-top: $padding-xs;
    margin-bottom: $padding-xs;
  }
  .text-container {
    h3 {
      line-height: 27px;
    }
  }
  .maps-container {
    width: 100%;
  }
  .contact-info {
    display: none;
  }
  .contactperson {
    margin-bottom: 30px;
  }

  #news-more {
    .btn-block {
      .image {
        background: transparent;
        height: auto;
      }
    }
  }

  footer {
    .footer-items {
      padding: $padding-sm 0 60px 0;
      text-align: left;
      h4 {
        margin: 45px 0 0 0;
        text-align: left;
        width: 100%;
        float: left;
        &.title-verhuur {
          margin-top: 15px;
        }
      }
      ul {
        width: 100%;
        float: left;
        li {
          width: 100%;
          text-align: left;
        }
      }
    }
    .bottom {
      .links {
        ul {
          li {
            padding: 0 8px 0 0;
            margin: 0 8px 0 0;
          }
        }
      }
    }
  }
  .top-bar {
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 16px 0 0 0;
    text-align: center;
    a {
      margin: 0 auto;
      float: none;
      i {
        display: block;
      }
    }
    span {
      display: none;
    }
  }
  .usp {
    max-width: 100%;
  }

  #references {
    .reference-item {
      margin-top: 15px;
      .reference-image,
      .reference-text {
        width: 100%;
        padding: 0 !important;
      }
      .reference-image {
        margin-bottom: 15px;
      }
    }
  }

  #results {
    ul {
      li {
        a {
          h2 {
            .label-primary {
              display: none;
            }
          }
        }
      }
    }
  }
  #search-form {
    .form-control {
      font-size: 2em;
    }
  }
  #btn-search-large {
    font-size: 1.5em;
    right: 15px;
    top: 15px;
  }

  #vacancies {
    .vacancy-item {
      .vacancy-image {
        width: 100%;
        height: auto;
        img {
          height: auto;
          width: 100% !important;
        }
      }
      .vacancy-text {
        height: auto;
        p {
          height: auto;
          padding-bottom: 25px;
        }
      }
    }
  }
  #newsletter .downloads {
    margin-top:30px;
    ul {
      margin-top:5px;
    }
  }

  #veiligheid {
    .downloads {
      margin-top:30px;
      float:left;
      ul {
        margin-top:5px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  #slide {
    height: 180px !important;
    min-height: 180px !important;
  }
  header {
    &.full {
      h1 {
        line-height: 44px;
        font-size: $font-size-36;
      }
    }
  }
  .main-nav li:nth-child(4) {
    margin-bottom:15px;
  }
  .main-nav li.phone {
    bottom:40px;
  }
  .main-nav li.mail {
    bottom:90px;
  }
}