.sidebar {
  .social-block {
    background:$font-color;
    color:white;
    width:100%;
    float:right;
    max-width:350px;
    display:block;
    padding:$padding-sm;
    margin-top:$padding-xs;
    h4 {
      font-size:$font-size-20;
      font-family: UniNeueHeavy, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      margin:0;
      padding:5px 0 0 0;
      float:left;
      display:inline;
    }
    .addthis_inline_share_toolbox {
      float:left;
      margin-left:10px;
      clear:none !important;
      svg.at-icon {
        fill: rgb(0, 0, 90) !important;
      }
    }
  }
  .blue-block {
    background:rgba(140,160,185,0.5);
    padding:$padding-sm;
    float:right;
    width:100%;
    max-width:350px;
    display:block;
    h3 {
      margin:0;
      padding:0;
      font-family: UniNeueHeavy, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size:$font-size-30;
      color:$font-color;
    }
  }
  .btn-block-blue {
    float:right;
    width:100%;
    max-width:350px;
  }
  .blue-block + .btn-block-blue {
    margin-top:$padding-sm;
  }
  .links {
    ul {
      margin:$padding-xs 0 0 0;
      padding:0;
      list-style:none;
      width:100%;
      float:left;
      li {
        width:100%;
        float:left;
        margin-bottom:5px;
        a, button {
          float:left;
          width:100%;
          position:relative;
          box-shadow:none;
          border:0;
          background:white;
          padding:7px 15px 6px 15px;
          text-align:left;
          border-radius:0;
          font-family: UniNeueBold, 'Roboto', sans-serif;
          font-weight: normal;
          font-style: normal;
          font-size:$font-size-18;
          z-index:10;
          &:before {
            content:"";
            top:0;
            right:0;
            width:0;
            height:40px;
            position:absolute;
            opacity:0;
            z-index:-1;
            background:$font-color;
            color:white;
          }
          &:after {
            content:"\f002";
            font-family:'VoetVerhuur';
            width:40px;
            height:40px;
            background:$button-color;
            color:white;
            display:block;
            position:absolute;
            left:auto;
            right:0;
            top:0;
            font-size:15px;
            text-align:center;
            padding-top:10px;
            z-index:11;
          }
          &:hover,
          &:focus {
            color:white !important;
            z-index:11;
            &:before {
              width:100%;
              opacity:1;
              content:"";
            }
            &:after {
              content:"\f002";
              background:transparent;
            }
          }
        }
        &:last-child {
          margin-bottom:0;
        }
      }
    }
  }
}