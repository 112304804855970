@media only screen and (max-width : 1399px) {
  header {
    &.full {
      .header-title {
        h1 {
          width: 65%;
        }
      }
    }
  }
}