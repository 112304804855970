#about {
  .text-intro {
    margin-bottom:$padding-md;
  }
}
.about-item {
  margin:$padding-sm 0 0 0;
  width:100%;
  float:left;
  display:block;
  .text-item {
    width:55%;
    float:left;
    margin:0;
    background:white;
    padding:$padding-md;
    position:relative;
    min-height:470px;
    .category {
      position:relative;
      padding-left:36px;
      color:$text-color;
      font-size:$font-size-18;
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      &:before {
        content:"\f004";
        font-family: 'VoetVerhuur';
        font-size:$font-size-20;
        position:absolute;
        left:0;
        top:-10px;
        color:$text-color;
      }
    }
    h2 {
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size:$font-size-38;
      margin:25px 0 10px 0;
      padding:0;
    }
    p {
      font-size:$font-size-24;
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      margin:0 0 50px 0;
      height:170px;
      overflow:hidden;
    }
    .btn-arrow {
      position:absolute;
      left:$padding-md;
      bottom:$padding-md;
    }
  }
  .image-item {
    float:right;
    width:45%;
    margin:0;
    padding:0;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
  }
  &:nth-child(odd) {
    .text-item {
      float:left;
    }
    .image-item {
      float:right;
    }
  }
  &:nth-child(even) {
    .text-item {
      float:right;
    }
    .image-item {
      float:left;
    }
  }
}
#solutions {
  .about-item {
    .text-item {
      p {
        font-size:1.175em;
      }
    }
  }
}

#veiligheid {
  .text {
    float:left;
    width:100%;
    max-width:620px;
  }
  h2 {
    margin-bottom:0;
    margin-top:0;
    font-family: UniNeueBold, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size:$font-size-38;
  }
  p {
    font-family: UniNeueRegular,Roboto,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.175em;
    line-height:1.8;
  }

  .downloads {
    width:100%;
    max-width:320px;
    float:right;
    position:relative;
    ul {
      list-style:none;
      margin:45px 0 0 0;
      padding:0;
      li {
        float:left;
        width:100%;
        margin:0 0 10px 0;
        a {
          display:block;
          background:$font-color;
          color:white;
          padding:10px 15px;
          width:100%;
          float:left;
          position:relative;
          &:after {
            content:"\f002";
            font-family: 'VoetVerhuur';
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            font-size:12px;
            position:absolute;
            right:15px;
            left:auto;
            top:10px;
            width:14px;
            height:18px;
            color:white;
            background:transparent;
          }
          &:hover,
          &:focus {
            background:white;
            color:$font-color;
            &:after {
              color:$font-color;
            }
          }
        }
      }
    }
  }
}