.btn {
  outline:0 !important;
  &:hover,
  &:focus {
    outline:0 !important;
  }
}
.btns {
  position:relative;
  .readmore {
    position:absolute;
    bottom:0;
    right:0;
    height:40px;
    width:100%;
    span {
      font-size:$font-size-16;
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      position:absolute;
      height:40px;
      right:0;
      top:0;
      padding:9px 10px 8px 15px;
      color:white;
      text-transform:lowercase;
      transition:ease all 0.25s;
      z-index:9;
      opacity:0;
    }
    &:after {
      content:"\f002";
      font-family:'VoetVerhuur';
      width:40px;
      height:40px;
      background:$button-color;
      color:white;
      display:block;
      position:absolute;
      right:0;
      top:0;
      font-size:15px;
      text-align:center;
      padding-top:10px;
      transition:ease all 0.25s;
      z-index:10;
    }
  }
  &:hover,
  &:focus {
    .readmore {
      span {
        opacity:1;
        background:$font-color;
        right:40px;
      }
      &:after {
        background:$font-color;
      }
    }
  }
}
.btn-scroll {
  position:absolute;
  right:$padding-xl;
  bottom:$padding-md;
  font-size:$font-size-18;
  font-family: UniNeueHeavy, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  color:white;
  padding-left:25px;
  z-index:11;
  i {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size:12px;
    position:absolute;
    left:0;
    bottom:8px;
    width:14px;
    height:18px;
  }
  &:hover,
  &:focus {
    text-decoration:none;
    color:$font-color;
    outline:0;
    &:after {
      width:0;
    }
  }
}
.btn-scrollup {
  position:absolute;
  bottom:0;
  right:0;
  width:54px;
  height:54px;
  background:$font-color;
  display:block;
  text-align:center;
  padding-top:13px;
  opacity:0;
  i {
    font-size:$font-size-26;
    color:white;
  }
  &.show {
    opacity:1;
  }
  &:after {
    width:0;
    height:0;
  }
}
.btn-block {
  width:100%;
  margin:$padding-sm auto;
  display:block;
  position:relative;
  overflow:hidden;
  .image {
    overflow:hidden;
    width:100%;
    height:100%;
    display:block;
    -moz-transition: all 0.25s ease-in-out 0s;
    -webkit-transition: all 0.25s ease-in-out 0s;
    transition: all 0.25s ease-in-out 0s;
  }
  .text {
    background:white;
    float:left;
    width:100%;
    min-height:256px;
    position:relative;
    padding:$padding-sm;
    padding-bottom:45px;
    text-align:left;
    display:block;
    h3 {
      font-size:$font-size-26;
      font-family: UniNeueHeavy, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      margin:0 0 10px 0;
      padding:0;
      color:$font-color;
    }
    p {
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size:$font-size-20;
      color:$text-color;
      margin:0;
      overflow:hidden;
      max-height:125px;
    }

  }
  &:hover,
  &:focus {
    .image {
      -webkit-transform: scale(1.05);
      -ms-transform:scale(1.05);
      transform:scale(1.05);
    }
    .text {
      h3 {
        color:$font-color;
      }
    }
    &:after {
      width:0;
    }
  }
}
/*
.btn-block {
  max-width:320px;
}
*/
.btn-service {
  max-width:100%;
  height:315px;
  display:block;
  h2 {
    font-size:$font-size-26;
    margin:0;
    padding:0 0 10px 70px;
    color: $font-color;
    min-height:67px;
  }
  i {
    color: $font-color !important;
  }
  .text {
    background:white;
    height:315px;
    display:block;
    p {
      max-height:144px;
      margin:15px 0;
      color:$card-color;
    }
  }
  &:hover,
  &:focus {
    h2, i {
      color: $font-color;
    }
  }
}
.btn-power {
  max-width:100%;
  margin:30px auto 0 auto;
  .text {
    height:290px;
    h2 {
      margin:0;
      padding:0;
      font-size:$font-size-26;
      color:$font-color;
    }
    p {
      margin:15px 0;
      font-size:$font-size-18;
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      max-height:160px;
      color:$card-color;
    }
  }
}
#aggregaten-overview {
  .btn-power {
    .text {
      height:230px;
    }
  }
}
.btn-block-blue {
  background:rgba(133,153,178,0.75);
  color:$font-color;
  padding:$padding-sm;
  width:100%;
  float:left;
  display:block;
  position:relative;
  min-height:429px;
  h3 {
    margin:0 0 10px 0;
    padding:0 15px 0 0;
    font-size:$font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  p {
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size:$font-size-18;
  }
  ul {
    margin:$padding-xs 0;
    padding:0;
    list-style:none;
    width:100%;
    float:left;
    li {
      float:left;
      width:100%;
      display:block;
      position:relative;
      padding:1px 0 1px 30px;
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size:$font-size-18;
      &:before {
        content:"\f005";
        font-family: 'VoetVerhuur';
        position:absolute;
        left:0;
        top:2px;
        width:15px;
        height:15px;
        color:white;
      }
    }
  }
  &:hover,
  &:focus {
    text-decoration:none;
    color:$font-color;
    &:after {
      width:0;
    }
  }
}

.btn-news {
  width:100%;
  display:block;
  float:left;
  position:relative;
  background:#eee;
  min-height:429px;
  z-index:0;
  overflow:hidden;
  span {
    &.news-title {
      font-family: UniNeueHeavy, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size:$font-size-20;
      color:white;
      position:absolute;
      background:$text-color;
      left:0;
      top:$padding-sm;
      padding:6px $padding-sm;
      z-index:10;
    }
    &.title {
      position:absolute;
      bottom:$padding-sm;
      left:$padding-sm;
      max-width:320px;
      z-index:11;
      h2, h3 {
        margin:0;
        padding:0;
        color:white;
      }
      h2 {
        font-size:$font-size-36;
        font-family: UniNeueHeavy, 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
      }
      h3 {
        font-size:$font-size-18;
        font-family: UniNeueRegular, 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        margin:0 0 10px 0;
      }
    }
  }
  &:before {
    content:"";
    position:absolute;
    left:-45%;
    top:0;
    height:100%;
    width:100%;
    background: -moz-linear-gradient(left, rgba(0,0,90,1) 0%, rgba(125,185,232,0) 100%);
    background: -webkit-linear-gradient(left, rgba(0,0,90,1) 0%,rgba(125,185,232,0) 100%);
    background: linear-gradient(to right, rgba(0,0,90,1) 0%,rgba(125,185,232,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00005a', endColorstr='#007db9e8',GradientType=1 );
    transition:ease all 0.25s;
  }
  &:hover,
  &:focus {
    .image {
      -webkit-transform: scale(1.05);
      -ms-transform:scale(1.05);
      transform:scale(1.05);
    }
    &:before {
      left:0;
    }
    &:after {
      width:0;
    }
  }
}
.btn-item {
  position:relative;
  float:left;
  width:100%;
  overflow:hidden;
  margin:0 0 $padding-sm 0;
  .title {
    position:absolute;
    bottom: 50px;
    left:-80%;
    max-width:80%;
    width:80%;
    transition:ease all 0.25s;
    opacity:1;
  }
  h2 {
    position:relative;
    font-size:$font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    color:white;
    line-height:1.2em;
    margin:0;
    padding:0;
  }
  h3 {
    position:relative;
    font-size:$font-size-16;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    color:white;
    line-height:1.3em;
    margin:0 0 5px 0;
    padding:0;
  }
  &:before {
    content:"";
    position:absolute;
    left:-25%;
    top:0;
    height:100%;
    width:100%;
    transition:ease all 0.4s;
    background: -moz-linear-gradient(left, rgba(0,0,90,1) 0%, rgba(125,185,232,0) 100%);
    background: -webkit-linear-gradient(left, rgba(0,0,90,1) 0%,rgba(125,185,232,0) 100%);
    background: linear-gradient(to right, rgba(0,0,90,1) 0%,rgba(125,185,232,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00005a', endColorstr='#007db9e8',GradientType=1 );
    opacity:0;
  }
  &:hover,
  &:focus {
    .title {
      left:30px;
    }
    &:before {
      opacity:1;
      left:0;
    }
    &:after {
      height:0;
      width:0;
    }
  }
}
.btn-arrow,
.btn-send {
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size:$font-size-18;
  background:$menu-color;
  color:white;
  display:block;
  position:relative;
  padding:12px 45px 10px 15px;
  border:0;
  box-shadow:none;
  transition: ease all 0.2s;
  &:after {
    content:"\f002";
    font-family: 'VoetVerhuur';
    position:absolute;
    right:15px;
    top:12px;
    font-size:15px;
    color:white;
  }
  &:hover,
  &:focus {
    outline:0;
    color:white;
    background:$font-color;
  }
}
.btn-arrow {
  display:inline-block;

  &:before {
    content:"\f002";
    font-family: 'VoetVerhuur';
    position:absolute;
    right:15px;
    top:14px;
    font-size:15px;
    color:white;
  }
  &:after {
    content:"";
    width:0;
    height:0;
  }
}
.btn-newsletter {
  display:block;
  background:transparent;
  width:40px;
  height:44px;
  border:0;
  position:relative;
  border-bottom:1px solid $menu-color;
  box-shadow:none;
  &:after {
    content:"\f002";
    font-family: "VoetVerhuur";
    color:$menu-color;
    position:absolute;
    right:0;
    margin:0 auto;
    display:block;
    text-align:center;
    top:10px;
    left:0;
    font-size:15px;
  }
  &:hover,
  &:focus {
    &:after {
      color:$font-color;
    }
  }
}
.btn-search {
  background:rgba(138,158,183,0.25);
  padding:$padding-md;
  color:$font-color;
  width:100%;
  float:left;
  position:relative;
  display:block;
}
.main-nav {
  #btn-search {
    position:absolute;
    background:transparent;
    border:0;
    box-shadow:0;
    color:$font-color;
    right:0;
    top:2px;
    font-size:$font-size-18;
  }
}
#btn-search-large {
  position:absolute;
  background:transparent;
  border:0;
  box-shadow:0;
  color:$font-color;
  right:0;
  top:2px;
  font-size:$font-size-44;
}

.btn-video {
  width:100%;
  display:block;
  position:relative;
  height:450px;
  margin-top:80px;
  .play-button {
    position:absolute;
    z-index:300;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background:url('/images/icons/play.png') 0 0 no-repeat;
    background-size:256px 128px;
    width:128px;
    height:128px;
    overflow:hidden;
  }
  &:after {
    content:"";
    position:absolute;
    z-index:1;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:100%;
    height:100%;
    opacity:0;
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,90,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,90,1) 0%,rgba(255,255,255,0) 100%);
    background: radial-gradient(ellipse at center, rgba(0,0,90,1) 0%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00005a', endColorstr='#00ffffff',GradientType=1 );
    transition:ease-in-out all 0.25s;
  }
  &:hover {
    .play-button {
      background:url('/images/icons/play.png') -128px 0 no-repeat;
      background-size:256px 128px;
    }
    &:after {
      opacity:0.2;
    }
  }
}