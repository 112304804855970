$font-color:                  #00005a;
$menu-color:                  #8ea2bb;
$button-color:                #8599b2;
$block-color:                 #8ca0b9;
$text-color:                  #808aa3;
$card-color:                  #8090a9;

$fontUni:                      'UniNeueRegular', 'Roboto' sans-serif;
$fontUniBold:                  'UniNeueBold', 'Roboto' sans-serif;
$fontUniBlack:                 'UniNeueBlack', 'Roboto' sans-serif;

$font-size-14:                  0.875em;
$font-size-16:                  1em;
$font-size-18:                  1.175em;
$font-size-20:                  1.250em;
$font-size-24:                  1.500em;
$font-size-26:                  1.625em;
$font-size-30:                  1.875em;
$font-size-36:                  2.250em;
$font-size-38:                  2.375em;
$font-size-44:                  2.750em;
$font-size-46:                  2.875em;
$font-size-60:                  3.750em;

$padding-xl:                    90px;
$padding-md:                    60px;
$padding-sm:                    30px;
$padding-xs:                    15px;

