#news-item {
  .text-intro {
    margin-bottom:$padding-md;
    text-align:left;
    span.date {
      display:block;
      width:100%;
      float:left;
      color:$menu-color;
      margin-bottom:15px;
      font-size:$font-size-16;
      font-family: $fontUni;
    }
    h1 {
      text-align:left;
    }
    p {
      text-align:left;
      margin:0;
      font-size:$font-size-24;
    }
  }
}

#news-more {
  .btn-block {
    max-width:100%;
    position:relative;
    h3 {
      height:100px;
      overflow:hidden;
    }
    .text {
      min-height:210px;
    }
    .image {
      overflow:hidden;
      height:200px;
      &:before {
        content:"";
        position:absolute;
        left:-100%;
        top:0;
        height:100%;
        width:100%;
        background: -moz-linear-gradient(left, rgba(0,0,90,1) 0%, rgba(125,185,232,0) 100%);
        background: -webkit-linear-gradient(left, rgba(0,0,90,1) 0%,rgba(125,185,232,0) 100%);
        background: linear-gradient(to right, rgba(0,0,90,1) 0%,rgba(125,185,232,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00005a', endColorstr='#007db9e8',GradientType=1 );
        transition:ease all 0.25s;
        z-index:10;
      }
    }
    &:hover,
    &:focus {
      h4 {
        color:$font-color;
      }
      .image {
        &:before {
          content:"";
          left:0;
        }
      }
    }
  }
}

.carousel-container {
  float:left;
  width:100%;
  margin-bottom:$padding-md;

  .owl-carousel {
    .owl-dots {
      position:relative;
      margin-top:-30px;
      text-align: center;
      .owl-dot {
        display: inline-block;
        zoom:1;
        span {
          width: 10px;
          height: 10px;
          margin: 5px 2px;
          background: white;
          display: block;
          transition: opacity .2s ease;
          border-radius: 30px;
          -webkit-backface-visibility: visible;
        }
        &.active {
          span {
            background:$font-color;
          }
        }
        &:hover,
        &:focus {
          span {
            background:$font-color;
          }
        }
      }
    }
  }
}