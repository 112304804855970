.options {
  .option {
    display:block;
    float:left;
    ul {
      margin:0 0 $padding-sm;
      padding:0;
      width:100%;
      float:left;
      list-style:none;
      li {
        float:left;
        width:100%;
        position:relative;
        display:block;
        font-size:$font-size-18;
        color:$font-color;
        padding:0 0 3px 0;
        margin:0 0 6px 0;
        font-family: UniNeueRegular, 'Roboto', sans-serif;
        font-weight:normal;
        font-style:normal;
        &:nth-child(odd) {
          margin-top:10px;
          font-family: UniNeueHeavy, 'Roboto', sans-serif;
          font-weight:normal;
          font-style:normal;
          &:before {
            content:"";
            position:absolute;
            bottom:0;
            width:100%;
            height:2px;
            background:$menu-color;
          }
        }
      }
    }
  }
  .col8 {
    width:65%;
    padding-right:$padding-xl;
  }
  .col4 {
    width:35%;
  }
  .colfull {
    width:100% !important;
    padding-right:0 !important;
  }
}
.usp {
  max-width:350px;
  float:right;
  margin-top:$padding-sm;
}

.breadcrumb {
  background:white;
  padding:0 0 5px 0;
  border-radius:0;
  a {
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight:normal;
    font-style:normal;
    font-size:80%;
    color:$card-color;
    text-transform: uppercase;
    &:hover,
    &:focus {
      color:$font-color;
    }
  }
  i {
    color:$card-color;
    font-size:12px;
    margin-left:3px;
    margin-right:3px;
  }
}