#newsletter {
  background:rgba(142,162,187,0.25);
  h2 {
    margin:0 0 10px 0;
    font-size:$font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  p {
    font-size:$font-size-18;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }

  .downloads {
    width:100%;
    max-width:320px;
    float:right;
    position:relative;
    ul {
      list-style:none;
      margin:45px 0 0 0;
      padding:0;
      li {
        float:left;
        width:100%;
        margin:0 0 10px 0;
        a {
          display:block;
          background:$font-color;
          color:white;
          padding:10px 15px;
          width:100%;
          float:left;
          position:relative;
          &:after {
            content:"\f002";
            font-family: 'VoetVerhuur';
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            font-size:12px;
            position:absolute;
            right:15px;
            left:auto;
            top:10px;
            width:14px;
            height:18px;
            color:white;
            background:transparent;
          }
          &:hover,
          &:focus {
            background:$menu-color;
          }
        }
      }
    }
  }
  .signup {
    margin-top:30px;
    .form-control {
      &:focus {
        border-color:$menu-color;
      }
    }
  }
}