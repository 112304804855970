@media only screen and (max-width : 1199px) {
  header {
    &.full {
      .header-title {
        h1 {
          width:75%;
        }
      }
    }
  }
  #slide {
    height:450px !important;
    min-height:450px !important;
  }
  .text-intro {
    h1, h2 {
      font-size:$font-size-38;
    }
    p {
      font-size:$font-size-24;
    }
  }
  .sidebar {
    .blue-block,
    .btn-block-blue {
      h3 {
        font-size:$font-size-26;
      }
    }
  }
  .btn-block-blue {
    h3 {
      font-size:$font-size-26;
    }
  }
  .btn-block {
    .text {
      min-height:320px;
      p {
        max-height:195px;
      }
    }
  }
  .btn-service {
    height:405px;
    .text {
      height:405px;
      h2 {
        font-size:$font-size-24;
      }
      p {
        font-size:$font-size-18;
        max-height:240px;
      }
    }
  }
  .btn-item {
    h2 {
      font-size:$font-size-24;
    }
  }
  .about-item {
    .image-item {
      height:385px !important;
    }
    .text-item {
      padding:$padding-sm;
      min-height:385px;
      h2 {
        margin-top:18px;
      }
      p {
        font-size:$font-size-20;
        height:168px;
      }
      .btn-arrow {
        left:$padding-sm;
        bottom:$padding-sm;
      }
    }
  }

  #news-home {
    .btn-block-blue {
      ul {
        margin:4px 0;
      }
    }
  }
  #news-more {
    .btn-block {
      .image {
        height:180px;
        background:$font-color;
      }
    }
  }
  #news-home,
  #expertises,
  #maps {
    .container {
      width:100%;
      padding-left:15px;
      padding-right:15px;
    }
  }
  #power-products {
    .container {
      width:100%;
      padding-left:15px;
      padding-right:15px;
    }
  }
  #aggregaten-overview {
    .container {
      width:100%;
      padding-left:15px;
      padding-right:15px;
    }
    .btn-power {
      .text {
        height:370px;
        p {
          max-height:240px;
        }
      }
    }
  }
  .btn-power {
    .text {
      height:370px;
      p {
        max-height:240px;
      }
    }
  }
  #vacancies .vacancy-item .vacancy-text h2 {
    font-size:$font-size-30;
  }
}