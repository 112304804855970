#home-logistics {
  .logistic-item {
    margin:$padding-sm 0 0 0;
    width:100%;
    float:left;
    display:block;
    .text-item {
      width:33.333333%;
      float:right;
      margin:0;
      background:white;
      padding:$padding-sm;
      position:relative;
      min-height:470px;
      h2 {
        font-size:$font-size-26;
        font-family: UniNeueHeavy, 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        margin:0 0 10px 0;
        color:$font-color;
        padding:0;
      }
      p {
        font-family: UniNeueBold, 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size:$font-size-20;
        color:$text-color;
        margin:0;
      }
    }
    .image-item {
      float:left;
      width:66.666667%;
      margin:0;
      padding:0;
      overflow:hidden;
      background-size:cover;
      background-repeat:no-repeat;
      background-position:center center;
      transition: transform 0.25s ease-in-out;
    }
    .btn-block {
      &:hover,
      &:focus {
        .text-item {
          h2 {
            color:$font-color;
          }
        }
      }
    }
    &:hover,
    &:focus {
      .image-item {
        transform:scale(1.05);
      }
    }
  }
}