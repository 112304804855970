#contact-info {
  h1 {
    margin:0 0 10px 0;
    padding:0;
    color:$font-color;
  }
  .info {
    p, a {
      color:$font-color;
      font-family: UniNeueRegular, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
    }
    p {
      font-size:$font-size-24;
    }
    strong {
      font-family: UniNeueHeavy, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
    }
  }
  .maps-container {
    margin-top:$padding-sm;
    width:100%;
  }
}