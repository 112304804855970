.form-group {
  float:left;
  width:100%;
  margin:0 0 45px 0;
}
.form-control {
  background:transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size:$font-size-24;
  color:$font-color;
  height:44px;
  padding:0;
  border-radius:0;
  border-color:$block-color;
  border:0;
  border-bottom:1px solid $block-color;
  box-shadow:none;
  &:focus,
  &:active {
    box-shadow:none;
    border-color:$font-color;
  }
}
textarea.form-control {
  resize:none;
  height:132px;
  padding:6px 12px;
  border:1px solid $block-color;
}
.small-input {
  padding-right:10px;
  float:left;
  width:50%;
}
.small-input + .small-input {
  padding-right:0;
  padding-left:10px;
}

.checkbox label {
  font-size:17px;
}

.has-error {
  .form-control {
    box-shadow:none;
    &:hover,
    &:focus,
    &:active {
      box-shadow:none;
    }
  }
}
.alert {
  border-radius:0;
}

label {
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size:$font-size-24;
  color:$menu-color;
}

.form-control::-webkit-input-placeholder {
  color: $menu-color;
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
}
.form-control:focus::-webkit-input-placeholder {
  color: $font-color;
}
.form-control:-moz-placeholder {
  color: $menu-color;
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
}
.form-control:focus:-moz-placeholder {
  color: $font-color;
}
.form-control::-moz-placeholder {
  color: $menu-color;
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
}
.form-control:focus::-moz-placeholder {
  color: $font-color;
}
.form-control:-ms-input-placeholder {
  color: $menu-color;
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
}
.form-control:focus:-ms-input-placeholder {
  color: $font-color;
}

#search {
  .form-control {
    border-color:$font-color;
    font-size:$font-size-18;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    height:34px;
  }
  .form-control::-webkit-input-placeholder {
    color: $font-color;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  .form-control:focus::-webkit-input-placeholder {
    color: $font-color;
  }
  .form-control:-moz-placeholder {
    color: $font-color;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  .form-control:focus:-moz-placeholder {
    color: $font-color;
  }
  .form-control::-moz-placeholder {
    color: $font-color;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  .form-control:focus::-moz-placeholder {
    color: $font-color;
  }
  .form-control:-ms-input-placeholder {
    color: $font-color;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  .form-control:focus:-ms-input-placeholder {
    color: $font-color;
  }
}