footer {
  background:$font-color;
  float:left;
  width:100%;
  display:block;
  .footer-items {
    color:white;
    float:left;
    display:block;
    width:100%;
    padding:$padding-xl 0;
    h4 {
      ont-family: UniNeueHeavy, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size:$font-size-26;
    }
    p {
      margin:0;
      padding:0;
      font-family: UniNeueRegular, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size:$font-size-18;
      line-height:1.6em;
      max-width:320px;
    }
    a {
      display:inline-block;
      padding:2px 0;
      color:white !important;
      font-family: UniNeueRegular, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      line-height:1.6em;
      &:hover,
      &:focus {
        color:$menu-color;
      }
    }
    ul {
      margin:0;
      padding:0;
      list-style:none;
      li {
        float:left;
        width:100%;
        display:block;
        a {
          display:inline-block;
          padding:2px 0;
          color:white;
          font-family: UniNeueRegular, 'Roboto', sans-serif;
          font-weight: normal;
          font-style: normal;
          line-height:1.6em;
          &:hover,
          &:focus {
            color:$menu-color;
          }
        }
      }
      &.footer-contact {
        li {
          padding: 2px 0;
          font-family: UniNeueRegular, 'Roboto', sans-serif;
          font-weight: normal;
          font-style: normal;
          line-height:1.6em;

          a {
            padding: 0;
          }
        }
      }
    }
  }
  .bottom {
    background:white;
    width:100%;
    float:left;
    display:block;
    position:relative;
    .links {
      width: 100%;
      overflow: hidden;
      padding:$padding-xs 0;
      ul {
        list-style: none;
        position: relative;
        float: left;
        display: block;
        left: 50%;
        margin:0;
        padding:0;
        li {
          padding:0 15px 0 0;
          margin:0 15px 0 0;
          position: relative;
          float: left;
          display: block;
          right: 50%;
          font-family: UniNeueRegular, 'Roboto', sans-serif;
          font-weight: normal;
          font-style: normal;
          font-size:$font-size-14;
          color:$font-color;
          &:after {
            content:"";
            width:1px;
            height:13px;
            position:absolute;
            background:$font-color;
            right:0;
            top:2px;
          }
          &:last-child {
            &:after {
              width:0;
              height:0;
            }
          }
        }
      }
    }
  }
}