#contact-form {
  h2 {
    margin:0 0 5px 0;
    padding:0;
    color:$font-color;
    font-size:$font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  p {
    color:$font-color;
    font-size:$font-size-24;
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  button[disabled] {
      opacity:0.25;
      &:hover,
      &:focus {
        background:$menu-color;
      }
  }
}
.contactform {
  width:100%;
  float:left;
  margin-top:45px;
}