html, body {
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  font-size: 100%;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: auto !important;
  height: 100%;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-family: UniNeueBold;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  padding: 0;
  margin: 0;
}

.menu-container {
  width:314px;
  height:100%;
  background:$menu-color;
  position:fixed;
  left:-260px;
  z-index:100;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.menu-container.active {
  left:0;
}
.menu-toggle {
  background:transparent;
  border:0;
  cursor: pointer;
  height: 54px;
  width: 54px;
  position: absolute;
  top: 15px;
  right: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 250ms all;
  transition: 250ms all;
}
.menu-toggle-inner {
  display:block;
  background: $font-color;
  height: 2px;
  margin:0 auto;
  width: 20px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius:2px;
  -webkit-transition: 250ms all;
  transition: 250ms all;
}
.menu-toggle-inner:before {
  -webkit-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  transform: translateY(-6px);
}
.menu-toggle-inner:after {
  -webkit-transform: translateY(4px);
  -ms-transform: translateY(4px);
  transform: translateY(4px);
}
.menu-toggle-inner:before,
.menu-toggle-inner:after {
  background: $font-color;
  content: '';
  display: block;
  height: 2px;
  width: 20px;
  border-radius:3px;
}
.menu-toggle-inner.active:before {
  -webkit-transform: translateY(-0.38rem) rotate(-45deg);
  -ms-transform: translateY(-0.38rem) rotate(-45deg);
  transform: translateY(-0.38rem) rotate(-45deg);
}
.menu-toggle-inner.active:after {
  -webkit-transform: translateY(-0.5rem) translateX(0rem) rotate(45deg);
  -ms-transform: translateY(-0.5rem) translateX(0rem) rotate(45deg);
  transform: translateY(-0.5rem) translateX(0rem) rotate(45deg);
}
.menu-toggle-inner.active {
  background:transparent;
}
.text-wrapper {
  left: 0;
  width:100%;
  top:0;
  padding-left:54px;
  position:absolute;
  z-index:99;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow-x: hidden;
}
.text-wrapper.active {
  left:260px;
}

h1, h2, h3, h4, h5 {
  font-family: UniNeueBold, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: UniNeueHeavy, 'Roboto', sans-serif;
  font-size: $font-size-38;
}

h2 {
  font-size: $font-size-46;
  &.upper {
    font-size: $font-size-30;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
}

a {
  color: $font-color;
  position: relative;
  z-index: 1;
  /*&:after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    background: $menu-color;
    z-index: -1;
  }*/
  &:hover,
  &:focus {
    text-decoration: none;
    color: $menu-color;
    outline: 0;
    /*&:after {
      width: 100%;
    }*/
  }
}

section {
  width: 100%;
  float: left;
  position: relative;
  display: block;
  &.white {
    background: white;
  }
  &.blue {
    background: rgba(142, 162, 187, 0.5);
    color: $font-color;
  }
  &.blue-dark {
    background: #899db6;
    color: $font-color;
  }
  &.padded {
    padding: $padding-xl 0;
  }
  &.text {
    color: $font-color;
  }
}

.block-blue {
  background: rgba(133, 153, 178, 0.75);
  color: $font-color;
  padding: $padding-sm;
  width: 100%;
  float: left;
  display: block;
  min-height: 390px;
  h3 {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: $font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  p {
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: $font-size-18;
  }
  ul {
    margin: $padding-sm 0 0 0;
    padding: 0;
    list-style: none;
    width: 100%;
    li {
      float: left;
      width: 100%;
      display: block;
      position: relative;
      padding: 6px 0 6px 30px;
      font-family: UniNeueBold, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: $font-size-18;
      &:before {
        content: "\f00c";
        font-family: FontAwesome;
        position: absolute;
        left: 0;
        top: 4px;
        width: 15px;
        height: 15px;
        color: white;
      }
    }
  }
}

.top-bar {
  position: fixed;
  width: 100%;
  z-index: 100;
  left:0;
  padding-left:122px;
  padding-top: 16px;
  background: white;
  height: 80px;
  transition: ease all 0.5s;
  a {
    position: relative;
    display: block;
    float: left;
    i {
      font-size: 44px;
      color: $font-color;
      transition: color 0.4s;
    }
    &:after {
      width: 0;
    }
  }
  span {
    float: right;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: $font-size-16;
    color: $font-color;
    text-transform: uppercase;
    margin-right: $padding-sm;
    margin-top: 14px;
  }
  &.top-home {
    background: transparent;
    a {
      border-bottom:0;
      i {
        color: white;
      }
    }
  }
  &.fixed {
    background: white;
    -webkit-box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.2);
    a {
      i {
        color: $font-color;
      }
    }
  }
}
.top-bar.active {
    left:260px;
}

.top-home {
  span { display: none; }
  &.fixed {
    span {
      display: block;
    }
  }
}

.text-intro {
  width: 100%;
  max-width: 780px;
  display: block;
  margin: 0 auto;
  h1, h2 {
    text-align: center;
    font-size: $font-size-46;
    color: $font-color;
    margin: 0 0 20px 0;
  }
  p {
    font-family: UniNeueBold, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    font-size: $font-size-26;
    color: $font-color;
  }
}

.main-container {
  width: 100%;
  float: left;
  max-width: 650px;
  .text-intro,
  .text-container {
    h1, h2, p {
      text-align: left;
    }
  }
  .text-intro {
    margin: 0 0 30px 0;
  }
}

.text-container {
  width: 100%;
  float: left;
  font-family: UniNeueRegular, 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  p, li {
    font-family: UniNeueRegular, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: $font-size-18;
    color: $font-color;
    line-height: 1.6em;
  }
  p, ul, ol {
    margin: 0 0 36px 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
  }
  h1 {
    &:first-child {
      margin: 0 0 $padding-md 0;
    }
  }
  h2 {
    font-size: $font-size-24;
    margin: 0 0 5px 0;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  h3 {
    font-size: $font-size-18;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  a {
    font-family: UniNeueBold, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    z-index: 1;
    border-bottom:3px solid $menu-color;
    /*&:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -1px;
      background: $menu-color;
      z-index: -1;
    }*/
  }
  span {
    &.light {
      color: $text-color;
      font-size: $font-size-18;
      font-family: UniNeueRegular, 'Roboto', sans-serif;
      font-weight: normal;
      font-style: normal;
    }
  }
}

.items {
  h2.title {
    margin: 0;
    padding: 0;
    font-size: $font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
  }
}

