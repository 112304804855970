@media only screen and (max-width : 991px) {
  .container {
    width:100%;
    padding-left:15px;
    padding-right:15px;
  }
  section {
    &.padded {
      padding:$padding-md 0;
    }
  }
  #slide {
    height:350px !important;
    min-height:350px !important;
  }
  header {
    &.full {
      .header-title {
        h1 {
          width:85%;
        }
      }
    }
  }
  .text-intro {
    h1, h2 {
      font-size:$font-size-30;
    }
    p {
      font-size:$font-size-20;
    }
  }
  .expertise {
    .text {
      padding:15px;
      min-height:320px;
      h3 {
        font-size:1.5em;
      }
      p {
        max-height:200px;
      }
    }
  }
  #services {
    padding-top:30px;
    .btn-block {
      margin-bottom:0;
    }
  }
  .sidebar {
    .btn-block-blue,
    .blue-block {
      padding:$padding-xs;
    }
    .btn-block-blue {
      min-height:345px;
      ul {
        margin:0;
        li {
          font-size:$font-size-16;
        }
      }
    }
    .links {
      ul {
        li {
          a, button {
            font-size:$font-size-16;
            height:40px;
            padding:9px 15px 6px 15px;
          }
        }
      }
    }
  }

  #home-logistics {
    .image-item {
      width:100% !important;
    }
    .logistic-item {
      .text-item {
        width:100%;
        min-height:50px;
      }
    }
  }

  #aggregaten-overview {
    .btn-power {
      .text {
        height:230px;
      }
    }
  }

  #news-more {
    .btn-block {
      h3 {
        height:auto;
      }
      .text {
        min-height:2px;
      }
      .image {
        background:transparent;
        height:auto;
      }
    }
  }

  #expertises,
  #aggregaten-overview,
  #maps,
  #news-home,
  #power-products {
    .container {
      padding-left: 15px;
      padding-right:15px;
    }
  }

  footer {
    .footer-items {
      padding:$padding-md 0;
      p {
        max-width:100%;
      }
    }
    .col-md-6 {
      &:first-child {
        margin-bottom:30px;
      }
    }
  }
}