#vacancies-intro {
  .text-intro {
    h1, p {
      text-align:left;
    }
    p {
      margin-bottom:36px;
      &:last-child {
        margin-bottom:0;
      }
    }
  }
}
#references {
  h3 {
    font-size:$font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight:normal;
    font-style:normal;
    &.upper {
      text-transform:uppercase;
      margin-top:0;
    }
  }
  .reference-item {
    float:left;
    width:100%;
    margin-top:$padding-md;
    .reference-image {
      width:50%;
      max-height:340px;
      overflow:hidden;
    }
    .reference-text {
      width:50%;
      h3 {
        margin:0 0 5px 0;
        padding:0;
      }
      p {
        font-family: UniNeueRegular, 'Roboto', sans-serif;
        font-weight:normal;
        font-style:normal;
        font-size:$font-size-18;
        line-height:1.6em;
        margin:0 0 30px 0;
      }
    }
    &:nth-child(odd) {
      .reference-text {
        float:right;
        padding-left:30px;
      }
      .reference-image {
        float:left;
        padding-right:30px;
      }
    }
    &:nth-child(even) {
      .reference-text {
        float:left;
        padding-right:30px;
      }
      .reference-image {
        float:right;
        padding-left:30px;
      }
    }
  }
}
#vacancies {
  h3 {
    color:$font-color;
    font-size:$font-size-30;
    font-family: UniNeueHeavy, 'Roboto', sans-serif;
    font-weight:normal;
    font-style:normal;
    &.upper {
      text-transform:uppercase;
      margin-top:0;
    }
  }
  .vacancy-item {
    float:left;
    width:100%;
    margin-top:$padding-xs;
    .vacancy-image {
      width:220px;
      height:220px;
      overflow:hidden;
      float:left;
    }
    .vacancy-text {
      height:220px;
      overflow:hidden;
      background:rgba(140,160,185,0.25);
      padding:$padding-sm;
      h2 {
        color:$font-color;
        font-size:$font-size-38;
        font-family: UniNeueHeavy, 'Roboto', sans-serif;
        font-weight:normal;
        font-style:normal;
        margin:10px 0 5px 0;
        display:block;
        float:left;
        width:100%;
      }
      p {
        width:100%;
        display:block;
        height:80px;
        font-family: UniNeueRegular, 'Roboto', sans-serif;
        font-weight:normal;
        font-style:normal;
        font-size:$font-size-24;
        color:$font-color;
        margin:0;
      }
    }
    &:after {
      width:0;
      height:0;
    }
  }
}
.vacancy-details {
  margin:0;
  padding:0;
  list-style:none;
  width:100%;
  float:left;
  li {
    float:left;
    margin:0 8px 0 0;
    position:relative;
    padding-right:8px;
    border:0;
    color:$font-color;
    &:before {
      content:"";
      position:absolute;
      width:1px;
      height:13px;
      right:0;
      top:4px;
      background:$font-color;
    }
    &:last-child {
      margin-right:0;
      &:before {
        width:0;
        height:0;
      }
    }
  }
}
#vacancy-full {
  .text-intro {
    h1 {
      text-align:left;
      margin:0 0 8px 0;
    }
    p {
      text-align:left;
      margin:0 0 30px 0;
    }
  }
  .vacancy-details {
    margin:0 0 30px 0;
    li {
      font-size:$font-size-24;
      &:before {
        height:22px;
      }
    }
  }
  .text-container {
    ul, ol, li {
      margin:0 0 0 9px;
      padding:0;
    }
  }
}